import React, {useState, useCallback} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel, { Modal, ModalGateway } from "react-images";


import Gallery from "react-photo-gallery";

const Masonry = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile(
      sort: { fields: [absolutePath], order: ASC }
      filter: {
        relativeDirectory: { eq: "photos/masonry" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              src
              srcSet
              presentationHeight
              presentationWidth
              sizes
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`)

  const photos = data.allFile.edges.map(photo => ({
    src: photo.node.childImageSharp.fluid.src,
    width: photo.node.childImageSharp.fluid.presentationWidth,
    height: photo.node.childImageSharp.fluid.presentationHeight,
    sizes: photo.node.childImageSharp.fluid.sizes,
    srcSet: photo.node.childImageSharp.fluid.srcSet,
    alt: photo.node.alt,
    title: photo.node.alt
  }));

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let direction = 'row';
  const columns = global.innerWidth && global.innerWidth <= 1024 ? 1 : undefined;
  if (columns === 1) {
    direction = 'column';
  }
  
  return  (
    <>
      <section id="photos" className='my-8'>
        <h1 className="font-serif text-2xl mb-2 text-center">Photos</h1>
        <Gallery photos={photos} columns={columns} direction={direction} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </section>
    </>
  )
}

export default Masonry