import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Gallery from "react-photo-gallery";

const Services = () => {

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativeDirectory: { eq: "photos/featured" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                src
                srcSet
                presentationHeight
                presentationWidth
                sizes
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)



  const photos = data.allFile.edges.map(photo => ({
    src: photo.node.childImageSharp.fluid.src,
    width: photo.node.childImageSharp.fluid.presentationWidth,
    height: photo.node.childImageSharp.fluid.presentationHeight,
    sizes: photo.node.childImageSharp.fluid.sizes,
    srcSet: photo.node.childImageSharp.fluid.srcSet,
    alt: photo.node.alt,
    title: photo.node.alt
  }))

  const imgs = data.allFile.edges.map(photo => (
    <Img fluid={photo.node.childImageSharp.fluid} />
  ))

  return (
    <div
      id="services"
      title="Services"
      className="bg-white flex items-center"
    >
      <div className='hidden md:block md:flex-shrink-0 md:w-1/2 w-full h-full p-2'>
        {imgs}
      </div>
      <div className='px-8'>
        <h1 className="font-serif text-2xl mb-2">Services</h1>
        <p>
          Whether it's retaining and building on your existing 175k+ followers, or
          providing recommendations and support to implement your first marketing
          initiative – I always approach each brand with a unique and innovative
          perspective.{" "}
        </p>
        <ul className="list-disc p-6">
          <li>
            Social Media Marketing + Strategy
            <ul className="list-disc px-6 py-2">
              <li>Monitor engagement on all social platforms</li>
              <li>Post &amp; schedule content</li>
              <li>Respond to incoming messages on social platforms</li>
              <li>Leverage guest created content</li>
              <li>Facebook and Instagram sponsored posts</li>
            </ul>
          </li>
          <li>
            Social Reporting
            <ul className="list-disc px-6 py-2">
              <li>
                Track &amp; report on key metrics (gained followers, impressions,
                engagement)
              </li>
            </ul>
          </li>
          <li>
            Branding
            <ul className="list-disc px-6 py-2">
              <li>Develop brand guidelines</li>
              <li>
                Design and manage marketing calendars for all marketing
                initiatives (social media, newsletters, promotions)
              </li>
              <li>
                Manage email marketing strategy; copyright, email creatives &amp;
                content, audience segmentation, deployment, delivery, and
                reporting of metrics
              </li>
              <li>
                Proofread and verify all marketing material and advertising aligns
                with brand guidelines
              </li>
              <li>
                Collaborate with Public Relations agency/team to ensure goals and
                objectives are being met
              </li>
            </ul>
          </li>
          <li>
            Build Online Presence
            <ul className="list-disc px-6 py-2">
              <li>
                Assist in online listings such as Yelp, Google, TripAdvisor,
                Pinterest accounts in addition to social media profiles
              </li>
            </ul>
          </li>
          <li>
            Photography (iPhone) + Photo Styling
            <ul className="list-disc px-6 py-2">
              <li>
                Basic content creation (photography &amp; images for social media,
                website, eblasts, PR assets, etc.)
              </li>
            </ul>
          </li>
          <li>
            Identify on-brand social influencers and bloggers for collaboration
            <ul className="list-disc px-6 py-2">
              <li>
                Coordinate and monitor influencer content for multiple social
                channels, including live content (for example Instagram Stories)
              </li>
            </ul>
          </li>
          <li>
            General Consulting
            <ul className="list-disc px-6 py-2">
              <li>Research industry trends</li>
              <li>Competitor Analysis</li>
              <li>Provide strategic counsel/direction</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Services;