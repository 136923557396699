import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Navigation from "../components/navigation"
import Section from "../components/sections/section"
import Services from "../components/sections/services"
import Masonry from "../components/masonry";

const AboutMe = ({ image }) => {
  return (
    <Section
      id="about-me"
      title="Hello there!"
      image={<Img fluid={image.childImageSharp.fluid} />}
    >
      <p>
        I’m Colleen, a social media manager specializing in content and brand
        strategy located in Chicago. I come from a musical and artistic family
        with a passion for creativity. I have a growing vinyl collection, an
        obsession for coffee table books, a love for watercolor painting, and an
        eagerness for travel. I love capturing the things I do and love around
        me which has shaped my approach for social content strategy very
        naturally.
      </p>
      <div className="text-center mt-6 flex flex-col md:flex-row md:justify-center">
        <a href="mailto:colleen@apartment-two.com" className="btn m-1">
          <i className="fa fa-envelope-o" aria-hidden="true"></i> Email
        </a>
        <a
          href="https://www.instagram.com/codunn/"
          className="btn m-1"
          rel="noreferrer"
          target="_blank"
        >
          <i className="fa  fa-instagram" aria-hidden="true"></i> Instagram
        </a>
        <a
          href="https://www.linkedin.com/in/colleen-dunn-a421a234/"
          className="btn m-1"
          rel="noreferrer"
          target="_blank"
        >
          <i className="fa  fa-linkedin" aria-hidden="true"></i> LinkedIn
        </a>
      </div>
    </Section>
  )
}

const ExperienceAndResults = ({ image }) => {
  return (
    <Section
      id="experience-and-results"
      title="Experience + Results"
      image={<Img fluid={image.childImageSharp.fluid} />}
      imagePosition="right"
    >
      <p>
        With over nine years of experience in the social media field, I have
        found success and personal fulfillment by building brand awareness,
        monitoring engagement, and creating strategies that are authentic for
        diverse brands.
      </p>
      <ul className="list-disc p-4">
        <li>Marketing of multiple award-winning restaurants in Chicago</li>
        <li>
          Real-time social media coordination for key events:
          <ul className="list-disc px-6 py-1">
            <li>Hotel, Restaurant, and Nightclub Openings</li>
            <li>Chicago Social Media Week</li>
            <li>Chicago Ideas Week</li>
            <li>Lollapalooza Aftershows</li>
            <li>Charity Fundraising Events</li>
          </ul>
        </li>
        <li>Multi-year panelist, Mashable – Chicago Ideas Week</li>
        <li>
          Implemented initial Instagram presence and ongoing strategy for seven
          unique brands, resulting in a total of 51,400+ gained followers
        </li>
        <li>
          Oversaw the marketing of brands receiving 2.5 million impressions and
          35k engagements on average per month
        </li>
        <li>
          Photography repurposed on global brand’s Instragram accounts such as: A Color Story (825K Followers), A.W.A.K.E. Mode (145K Followers), Artifact Uprising (666K Followers)
        </li>
        <li>Monitored a nation-wide campaign for a luxury car brand</li>
      </ul>
    </Section>
  )
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      colleen: file(relativePath: { eq: "photos/colleen-dunn.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      experienceAndResults: file(
        relativePath: { eq: "photos/colleen-dunn-photo-charcuterie.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Navigation />
        <AboutMe image={data.colleen} />
        <ExperienceAndResults image={data.experienceAndResults} />
        <Services />
        <Masonry />
      </Layout>
      <Layout>
        <footer className="mx-2">
          <ul className="flex m-4 justify-center">
            <li className="mx-4">
              <a
                href="https://www.instagram.com/codunn/"
                className="anchor"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fa  fa-instagram" aria-hidden="true"></i> Instagram
              </a>
            </li>
            <li className="mx-4">
              <a
                href="https://www.linkedin.com/in/colleen-dunn-a421a234/"
                className="anchor"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fa fa-linkedin" aria-hidden="true"></i> LinkedIn
              </a>
            </li>
            <li className="mx-4">
              <a
                href="https://represent.io/codunn"
                className="anchor"
                rel="noreferrer"
                target="_blank"
              >
                Resume
              </a>
            </li>
          </ul>
          <small>Colleen Dunn | {new Date().getFullYear()}</small>
        </footer>
      </Layout>
    </>
  )
}

export default IndexPage
