import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Hero = ({ image }) => {
  const data = useStaticQuery(
    graphql`
      query {
        mast: file(relativePath: { eq: "photos/colleen-dunn-photo-golden-gate.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const imageData = data.mast.childImageSharp.fluid

  return (
    <header className='mb-4'>
      <BackgroundImage
        fluid={imageData}
        style={{
          height: "400px",
        }}
      >
        <div className="flex text-white p-2 justify-end">
          <div className="text-right">
            <h1 className="font-serif text-5xl">Colleen Dunn</h1>
            <p>Social Media Content &amp; Brand Strategy</p>
            <ul className="flex justify-end text-2xl">
              <li className="mx-2">
                <a
                  href="https://www.instagram.com/codunn/"
                  target="_blank"
                  rel="noreferrer"
                  title="@codunn on Instagram"
                  className="anchor"
                >
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
              <li className="ml-2">
                <a
                  href="https://www.linkedin.com/in/colleen-dunn-a421a234"
                  target="_blank"
                  rel="noreferrer"
                  title="Colleen Dunn on LinkedIn"
                  className="anchor"
                >
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </BackgroundImage>
    </header>
  )
}

export default Hero
