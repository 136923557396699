import React from "react"

const ImageWrapper = ({ image }) => (
  <div className="md:flex-shrink-0 md:w-1/2 w-full h-full p-2">{image}</div>
)

const Section = ({ id, title, image, imagePosition = "left", children }) => (
  <section id={id} className="md:flex items-center">
    {image && imagePosition === "left" ? <ImageWrapper image={image} /> : null}
    <div className="p-8 md:w-1/2 bg-white">
      <h1 className="font-serif text-2xl mb-2">{title}</h1>
      {children}
    </div>
    {image && imagePosition === "right" ? <ImageWrapper image={image} /> : null}
  </section>
)

export default Section
