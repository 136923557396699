import React from "react"

const Navigation = () => (
  <nav>
    <ul className="flex align-center justify-center m-4 text-center">
      <li className="p-2">
        <a className="anchor" href="#about-me">
          About Me
        </a>
      </li>
      <li className="p-2">
        <a className="anchor" href="#experience-and-results">
          Experience + Results
        </a>
      </li>
      <li className="p-2">
        <a className="anchor" href="#services">
          Services
        </a>
      </li>
      <li className="p-2">
        <a className="anchor" href="#photos">
          Photos
        </a>
      </li>
    </ul>
  </nav>
)

export default Navigation
